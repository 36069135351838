import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const WasKostetEinHausInOberosterreich = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Was kostet ein Haus in Oberösterreich?" showCalc={false} />
            <Article>
                <p>
                    Haus, der Traum! Auf der Suche nach dem passenden Eigenheim ist vor allem eines wichtig: sich
                    möglichst früh einen Überblick über das Angebot zu verschaffen und ein Gespür für regionale
                    Unterschiede auf dem Markt zu entwickeln. In den Artikeln dieser Serie widmen wir uns daher den
                    einzelnen Bundesländern und geben dir einen ersten Überblick über das dortige Angebot. Diesmal an
                    der Reihe: Oberösterreich.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Immobilienpreise in Österreich – die aktuelle Tendenzen</h2>
                <p>
                    Die Kreditzinsen sind in Österreich aktuell sehr günstig, weshalb besonders viele Menschen, derzeit
                    in Immobilien investieren. Die Folge ist ein rasanter Anstieg der Preise auf dem Wohnungsmarkt, der
                    seit dem vierten Quartal 2020 im zweistelligen Bereich liegt. Wohnraum wurde im ersten Quartal 2021
                    um 12,3 % teurer, im zweiten war ein Preisanstieg von 11,7 % zu verzeichnen. Während sich der
                    Anstieg der Kaufpreise für Einfamilienhäuser außerhalb Wiens 2021 von 12,9 % im ersten auf 11,3 % im
                    zweiten Quartal verringerte, wurden gebrauchte Eigentumswohnungen im zweiten Quartal um 14,3 %
                    teurer. Die Immobilienpreise außerhalb Wiens wachsen dabei im Allgemeinen schneller als innerhalb
                    der Landeshauptstadt: In Wien stiegen sie im ersten Quartal 2021 um 10,9, im zweiten um 10,7 %.
                    Dagegen waren in den anderen Bundesländern in diesem Zeitraum Wachstumsraten von 14 bzw. 12,8 % zu
                    beobachten. Besonders für den Neubauwohnungsmarkt gilt dieser Trend: Stiegen die Preise für neue
                    Immobilien in Wien 2021 um 10,4 im ersten und von 10, 1 % zweiten Quartal, so kam es in den übrigen
                    Bundesländern zu einem Anstieg von 16,7 und 12,9 %.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Die Preisentwicklung in Oberösterreich</h2>
                <p>
                    Eigentumswohnungen werden derzeit beinahe im gesamten oberösterreichischen Gebiet teurer. Besonders
                    ausgeprägt ist der Preisanstieg in Kirchdorf/Krems und Vöcklabruck. Hier liegt er jeweils zwischen 7
                    und 10 %. Ähnliches lässt sich zur Preisentwicklung von Einfamilienhäusern sagen. Auch diese werden
                    in nahezu allen Bezirken teurer, im Stadtgebiet von Wels sogar um knapp 10 %. Für Baugrundstücke
                    zahlst du in Oberösterreich zwischen 37, 21 und 266,25 €.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Das kosten Häuser in Oberösterreich</h2>
                <p>
                    Der Kaufpreis eines Einfamilienhauses im städtischen Bereich von Linz beträgt 1.868,56 € pro
                    Quadratmeter. Etwas günstiger steigst du beim Hauskauf in Wels (Stadt) aus, wo du mit einem Preis
                    von 1.594,74 pro Quadratmeter rechnen musst. Vöcklabruck ist mit einem Quadratmeterpreis von
                    1.506,83 schon erheblich billiger. Braunau liegt zwar am Inn, ist aus bekannten Gründen allerdings
                    weniger in, was sich zumindest positiv auf den Immobilienpreis auswirkt: 1.289,00 € kostet dich ein
                    Quadratmeter beim Hauskauf dort.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"wasKostetEinHausInOberosterreich"}></BreadcrumbList>
            <ArticleStructuredData
                page={"wasKostetEinHausInOberosterreich"}
                heading={"Was kostet ein Haus in Oberösterreich?"}
            />
        </Layout>
    );
};

export default WasKostetEinHausInOberosterreich;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.was-kostet-ein-haus-in-oberosterreich", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
